export type HttpHeaderApp = Partial<{
  id: string;
  version: string;
  build: string;
}>;

export type HttpHeaderDevice = Partial<{
  id: string;
  type: string;
  os: string;
  osVersion: string;
  model: string;
}>;

export const serializeObjectForHttpHeader = (obj: Record<string, unknown>) => {
  try {
    const searchParams = new URLSearchParams();
    Object.entries(obj).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.append(key, String(value));
      }
    });
    return searchParams.toString();
  } catch (e) {
    return '';
  }
};

export const parseObjectFromHttpHeader = <T extends { [key: string]: string }>(str: string): Partial<T> | undefined => {
  try {
    const searchParams = new URLSearchParams(str);
    const obj: Partial<T> = {};
    searchParams.forEach((value, key) => {
      // For some reason TS still isn't ok with manually casting key to "as keyof typeof obj"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      obj[key] = value;
    });
    return obj;
  } catch (e) {
    return undefined;
  }
};

export const PRIVATE_HEADER_PREFIX = 'x-playsuisse';
export const CLIENT_REQUEST_HEADERS = {
  profileId: `${PRIVATE_HEADER_PREFIX}-user-profile-id`,
  locale: {
    /**
     * @deprecated
     */
    v1: 'locale',
    v2: `${PRIVATE_HEADER_PREFIX}-locale`,
  },
  app: {
    /**
     * @deprecated
     */
    v1: 'appinfo',
    v2: `${PRIVATE_HEADER_PREFIX}-app`,
  },
  device: {
    /**
     * @deprecated
     */
    v1: 'deviceinfo',
    v2: `${PRIVATE_HEADER_PREFIX}-device`,
  },
  /**
   * @deprecated Use `id` in app v2 header
   */
  platform: 'platform',
} as const;
