import { getHostname } from '../index.js';

export const isImageKalturaUrl = (url: string) => !!getHostname(url)?.endsWith('kaltura.com');
export const isImageAkamaiKalturaUrl = (url: string) => {
  const hostName = getHostname(url);
  return !!hostName && hostName.includes('playsuisse-img.akamaized');
};
export const isImageAkamaiPlaySuisseStaticUrl = (url: string) => {
  const hostName = getHostname(url);
  return !!hostName && hostName.includes('playsuisse-static.akamaized');
};
export const isImageAkamaiArteUrl = (url: string) => {
  const hostName = getHostname(url);
  return !!hostName && hostName.includes('playsuisse-arte.akamaized');
};
export const isImageAkamaiThumbnailsUrl = (url: string) => {
  const hostName = getHostname(url);
  return !!hostName && hostName.includes('playsuisse-thumbnails.akamaized');
};
export const isImageAkamaiThumbnailsV2Url = (url: string) => {
  const hostName = getHostname(url);
  return !!hostName && hostName.includes('playsuisse-thumbnails-v2.akamaized');
};

export const isImageArteUrl = (url: string) => getHostname(url) === 'api-cdn.arte.tv';
export const isImageCDNUrl = (url: string) =>
  getHostname(url) === 'cdn.playsuisse.ch' || getHostname(url) === 'd18ssqkichhxht.cloudfront.net';

export const ARTE_COMPATIBLE_IMAGE_DIMENSIONS = [
  { width: 3840, height: 2160 },
  { width: 1920, height: 1080 },
  { width: 1280, height: 720 },
  { width: 640, height: 360 },
  { width: 340, height: 192 },
];

export const findClosestArteCompatibleImageDimension = (width: number, height: number) => {
  let distance = Number.MAX_SAFE_INTEGER;
  let closestDimensionIndex = 0;
  ARTE_COMPATIBLE_IMAGE_DIMENSIONS.forEach((dim, i) => {
    const currentDistance = Math.abs(dim.width - width) + Math.abs(dim.height - height);
    if (currentDistance < distance) {
      closestDimensionIndex = i;
      distance = currentDistance;
    }
  });
  return ARTE_COMPATIBLE_IMAGE_DIMENSIONS[closestDimensionIndex];
};
